import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComment, faClose, faWindowMaximize, faPaperPlane, faWindowRestore, faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
import './style.css';
config.autoAddCss = false;

function Chatbox() {
    const [isOpen, setIsOpen] = useState(false);
    const [isMaximized, setIsMaximized] = useState(false);
    const [chatMessage, setChatMessage] = useState("We make it simple and seamless for business and people to talk to each other. Ask us anything.");
    /*
    chatMessages object:
    {
      sender: "",
      message: "",
      dateTime: ""
    }
     */

    const addMsg = (msgObject) => {
        let chatChange = [...chatMessage];
        chatChange.push(msgObject);
        setChatMessage(msgObject);
    }

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
        setIsMaximized(false);
    };

    const handleMaximize = () => {
        setIsMaximized(true);
    };

    const handleMinimize = () => {
        setIsMaximized(false);
    };

    const handleSend = () => {
        const msg = document.getElementById("textarea").value;

        fetch('https://chatta.addamig.se/api/ChatGPTWebService/PostChat', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: '"' + removeLineBreaksFromString(msg) + '"'
        })
            .then((res) => res.text())
            .then((data) => {
                console.log(data);
                setChatMessage(data);
            })
            .catch((error) => {
                console.log("error");
                console.log(error);
                console.error(error);
            });   
    }

    const removeLineBreaksFromString = (stringToChange) => {
        return stringToChange.replace(/(?:\r\n|\r|\n)/g, '\\n');
    }

    return (
        <>
            {isOpen && (<>
                <div className="chatbox">
                    <button className="chat_button chatbox_close" onClick={handleClose}>
                        <FontAwesomeIcon icon={faClose} size="2x" />
                    </button>
                    {!isMaximized && <>
                        <section className="chatbox_popup">
                            <header className="chatbox_popup__header">
                                <aside style={{ flex: 3 }}>
                                    <FontAwesomeIcon icon={faUserCircle} size="4x" className="chatbox_popup__avatar" />
                                </aside>
                                <aside style={{ flex: 8 }}>
                                    <h1>Sussanah Austin</h1>
                                    <p>Agent (Online)</p>
                                </aside>
                                <aside style={{ flex: 1 }}>
                                    <button
                                        className="chat_button"
                                        onClick={handleMaximize}
                                    >
                                        <FontAwesomeIcon icon={faWindowMaximize} size="2x" />
                                    </button>
                                </aside>
                            </header>
                            <main className="chatbox_popup__main">
                                <p>
                                    {chatMessage}
                                </p>
                            </main>
                            <footer className="chatbox_popup__footer">
                                <aside style={{ flex: 10 }}>
                                    <textarea
                                        id="textarea"
                                        className="chat_textarea"
                                        type="text"
                                        placeholder="Type your message here..."
                                        autoFocus
                                    ></textarea>
                                </aside>
                                <aside style={{ flex: 1, color: "#888", textAlign: "center", cursor: "pointer" }}>
                                    <FontAwesomeIcon icon={faPaperPlane} onClick={handleSend} />
                                </aside>
                            </footer>
                        </section>
                    </>}
                    {isMaximized && (
                        <section className="chatbox_panel">
                            <header className="chatbox_panel__header">
                                <aside style={{ flex: 3 }}>
                                    <FontAwesomeIcon icon={faUserCircle} size="4x" className="chatbox_popup__avatar" />
                                </aside>
                                <aside style={{ flex: 6 }}>
                                    <h1>Sussanah Austin</h1>
                                    <p>Agent (Online)</p>
                                </aside>
                                <aside style={{ flex: 3, textAlign: "right" }}>
                                    <button className="chat_button" onClick={handleMinimize}>
                                        <FontAwesomeIcon icon={faWindowRestore} size="2x" />
                                    </button>
                                    <button className="chat_button" style={{ marginLeft: "14px" }}>
                                        <FontAwesomeIcon icon={faClose} size="2x" onClick={handleClose} />
                                    </button>
                                </aside>
                            </header>
                            <main className="chatbox_panel__main" style={{ flex: 1 }}>
                                <p>We make it simple and seamless for <br /> business and people to talk to each <br /> other. Ask us anything. </p>
                            </main>
                            <footer className="chatbox_panel__footer">
                                <aside style={{ flex: 10 }}>
                                    <textarea id="textarea" className="chat_textarea" type="text" placeholder="Type your message here..." autoFocus></textarea>
                                </aside>
                                <aside style={{ flex: 1, color: "#888", textAlign: "center" }}>
                                    <FontAwesomeIcon icon={faPaperPlane} onClick={handleSend} />
                                </aside>
                            </footer>
                        </section>
                    )}
                </div>
            </>)}
            {!isOpen && (
                <button className="chat_button chatbox_open" onClick={handleOpen}>
                    <FontAwesomeIcon icon={faComment} size="2x" />
                </button>
            )}
        </>
    );
}

export default Chatbox;